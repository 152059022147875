.btn {
    border: 0px;
    border-radius: 31px;
    font-size: 24px;
    font-weight: 700;
    font-family: arvo;
    height: 62px;
}

.btn-sm {
    width: 256px;
    height: 62px;

    padding: 9px 24px;
    min-height: 36px;
    border-radius: 40px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
}

.btn-primary {
    background-color: var(--bs-bg-0800);
    border-color: var(--bs-bg-0800);
    color: var(--bs-text-0800);


    img {
        margin-right: 10px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: var(--bs-hover-0800);
        border-color: var(--bs-hover-0800);
        box-shadow: none !important;
        color: var(--bs-text-0800);
    }

    @include media-breakpoint-down(xl) {
        padding: 4px 13px;

        width: 145px;
        height: 35px;

        font-family: Lato;
        font-weight: 900;
        font-size: 12px;

        img {
            margin-right: 10px;
            width: 13px;
            margin-bottom: 3px;
        }

    }
}

.btn-secondary {
    background-color: var(--bs-bg-button);
    border-color: var(--bs-bg-button);
    color: var(--bs-text-button);


    &:hover,
    &:active,
    &:focus {
        background-color: var(--bs-hover-button);
        border-color: var(--bs-hover-button);
        box-shadow: none !important;
        color: var(--bs-text-button);
    }

    @include media-breakpoint-down(xl) {
        width: 100%;
    }
}

.btn-link {
    font-family: Lato;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    background-color: transparent;

    color: var(--bs-primary);
    text-decoration: underline;
    background: none;
    border: 0px;
    display: flex;
    align-items: center;
}

.btn-readmore {
    color: var(--bs-black);
    font-weight: normal;
    font-size: 12px;
    padding: 0;
    line-height: 12px;
    height: auto;
    min-height: auto;
    border: 0px;
    background: white;
    text-decoration: underline;
}

a.btn {
    line-height: 46px;
}

.btn-creditcard {
    padding: 12px 20px;
    border-radius: 8px;

    background: var(--bs-white);
    border: 1px solid var(--bs-card-payment-border);
    box-sizing: border-box;

    border: 1px solid var(--bs-card-payment-border);
    box-sizing: border-box;

    animation: fadeIn 1s alternate;
    background-color: var(--bs-bg-field);
}

.btn-bank {
    padding: 4px;
    width: 64px;
    height: 50px;
    margin: 6px;
    display: flex;
    align-items: center;
    background: var(--bs-white);
    border: 1px solid var(--bs-card-payment-border);
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 16px;
    line-height: 18px;
    color: var(--bs-card-payment-text);
    cursor: pointer;

    &:hover {
        background: var(--bs-white);
    }

    &.active {
        animation: fadeIn 1s alternate;
        background: var(--bs-bg-field);
    }
}

.onlyLogo {
    padding: 12px 20px;
}